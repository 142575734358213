import _, { concat, uniqBy, reduce } from "lodash";
import {defineStore} from 'pinia';
import {reactive, ref, Ref, UnwrapNestedRefs} from "vue";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {CartCalculatorResponse} from "../../responses/Site/CartCalculatorResponse";
import {CartCalculatorLineResponse} from "../../responses/Site/CartCalculatorLineResponse";
import {api} from "../scripts/api";
import {ProductVariantResponse} from "../../responses/ProductVariantResponse";
import mitt, {Emitter} from 'mitt';

type Events = {
    afterAdding: void
}

export const useCartStore = defineStore('cart', () => {
    const events: Emitter<Events> = mitt<Events>();
    const cart: Ref<CartCalculatorResponse | null> = ref(null);
    const data: Ref<Array<any>> = ref([]); //todo cartDataResource?
    const cartItems: Ref<Array<CartCalculatorLineResponse>> = ref([]);
    const cartCourseItems: Ref<Array<CartCalculatorLineResponse>> = ref([]);
    const serviceItems: Ref<Array<any>> = ref([]);
    const lastOrderLine: Ref<Object> = ref({});
    const cart_uuid: Ref<String> = ref("");
    const cart_currency: Ref<String> = ref("");
    const delivery_country_code: Ref<String> = ref("");
    const addToCartSelectedVariant: Ref<ProductVariantResponse> = ref({});
    const addToCartQuantity: Ref<Number> = ref(1);
    //separate ref for modal variant, right now it's needed for related products on detail page
    const addToCartSelectedVariantModal: Ref<ProductVariantResponse> = ref({});
    const addToCartQuantityModal: Ref<Number> = ref(1);

    const getCartRequest: UnwrapNestedRefs<{
        timeout: number | null
        abort: AbortController | null
    }> = reactive({
        timeout: null,
        abort: null,
    })
    const updateQuantityRequest: UnwrapNestedRefs<{
        timeout: number | null
        abort: AbortController | null
    }> = reactive({
        timeout: null,
        abort: null,
    })

    const getUuid = () => {
        return cart_uuid.value ?? window.cart_uuid;
    }
    const getItemCount = () => {
        return cartItems.value.reduce((nr, curr) => nr + curr.model.quantity, 0);
    }
    const getDiscounts = () => {
        if(!cart || !cart.value?.discount_models) return null;
        return cart.value.discount_models.length === 0 ? [] : uniqBy((cart.value.discount_models), "code");
    }
    const getSubtotalExcl = () => {
        if(!cart.value) return 0;
        return cart.value.sub_total_excl;
    }
    const getTotal = () => {
        if(!cart.value) return 0;
        return cart.value.total;
    }
    const getDeliveryCostExcl = () => {
        if(!cart.value) return 0;
        return cart.value.delivery_cost_excl;
    }
    const getDiscountExcl = () => {
        if(!cart.value) return 0;
        return cart.value.discount_excl;
    }
    const getTaxes = () => {
        if(!cart.value) return 0;
        return cart.value.taxes;
    }
    const setAddToCartSelectedVariant = (selectedVariant) => {
        addToCartSelectedVariant.value = selectedVariant;
    }
    const setAddToCartSelectedVariantModal = (selectedVariant) => {
        addToCartSelectedVariantModal.value = selectedVariant;
    }
    const hasOnlyServices = () => {
        return cart.value?.services_only;
    }
    const loadCart = async <T>(): Promise<T> => {
        return new Promise<T>(async (resolve, reject): Promise<void> => {
            if (getCartRequest.abort !== null) {
                getCartRequest.abort.abort()
            }

            const abortController: AbortController = new AbortController()
            getCartRequest.abort = abortController

            try {
                const config: AxiosRequestConfig = {
                    signal: abortController.signal
                }

                const response: void | AxiosResponse<T> = await api().post(
                    '/site/cart/',
                    {
                        cart: getUuid(),
                        lang: window.language,
                        delivery_country_code: delivery_country_code.value
                    },
                    config
                ).then(response => {
                    setCart(response.data.cart);
                    setData(response.data.data);
                    setDeliveryCountryCode(response.data.delivery_country_code);
                    setCartItems(response.data.cart.lines);
                    setCartCourseItems(response.data.cart.courseLines);
                    setServiceItems(response.data.service);
                    resolve(response.data)
                })

            } catch (e) {
                reject(e)
            }
        });
    }
    const updateQuantity = async <T>(payload): Promise<T> => {
        return new Promise<T>(async (resolve, reject): Promise<void> => {
            payload.cart = cart_uuid.value;
            if (updateQuantityRequest.abort !== null) {
                updateQuantityRequest.abort.abort()
            }

            const abortController: AbortController = new AbortController()
            updateQuantityRequest.abort = abortController

            try {
                const config: AxiosRequestConfig = {
                    signal: abortController.signal
                }

                const response: void | AxiosResponse<T> = await api().post(
                    '/site/cart/line/update-quantity/',
                    _.merge(payload, { _method: "PUT" }),
                    config
                ).then(response => {
                    loadCart();

                    resolve(response.data)
                })
            } catch (e) {
                reject(e)
            }

        });
    }
    const updateInstallation = () => {
        // return new Promise((resolve, reject) => {
        //     payload.cart = this.uuid;
        //
        //     if (this.abortControllers["updateInstallation"]) {
        //         this.abortControllers["updateInstallation"].abort();
        //     }
        //     this.abortControllers["updateInstallation"] = new AbortController();
        //     axios.post("/api/site/cart/line/update-installation/", _.merge(payload, { _method: "PUT" }), {
        //         signal: this.abortControllers["updateInstallation"].signal,
        //     })
        //     .then(response => {
        //         this.loadCart();
        //         resolve(response);
        //     })
        //     .catch(error => {
        //         reject(error);
        //     });
        // });
    }
    const updateCountryCode = (deliveryCountryCode) => {
        return new Promise((resolve) => {
            setDeliveryCountryCode(deliveryCountryCode);
            loadCart().then(response => {
                resolve(response.data.data.delivery_country_code);
            });
        });
    }
    const addItem = (payload) => {
        payload.cart = cart_uuid.value;
        return new Promise((resolve, reject) => {
            api().post("/site/cart/line/", payload)
            .then(response => {
                if(response.data?.uuid) {
                    setUuid(response.data.uuid);
                }
                loadCart();
                resolve(response);
                events.emit('afterAdding');
            })
            .catch(error => {
                reject(error);
            });
        });
    }
    const validateDiscountCode = (payload) => {
        return new Promise((resolve, reject) => {
            payload.cart = cart_uuid.value;
            api().post("/site/cart/validate-discount-code/", _.merge(payload, { _method: "PUT" }))
            .then(response => {
                loadCart();
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    }
    const deleteDiscountCode = (payload) => {
        return new Promise((resolve, reject) => {
            payload.cart = cart_uuid.value;
            api().post("/site/cart/delete-discount-code/", _.merge(payload, { _method: "PUT" }))
            .then(response => {
                loadCart();
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    }
    const lookupAddress = (payload) => {
        return new Promise((resolve, reject) => {
            api().post("/site/cart/lookup-address/", _.merge(payload, { _method: "POST" }))
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    }
    const validateVatNumber = (payload) => {
        return new Promise((resolve, reject) => {
            payload.cart = cart_uuid.value;
            api().post("/site/cart/validate-vat-number/", _.merge(payload, { _method: "PUT" }))
            .then(response => {
                loadCart();
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    }
    const deleteLastOrderLine = () => {
        lastOrderLine.value = null;
    }
    const setCart = (cartValue) => {
        cart.value = cartValue;
    }
    const setData = (dataValue) => {
        data.value = dataValue;
    }
    const setCartItems = (items) => {
        cartItems.value = items;
    }
    const setCartCourseItems = (items) => {
        cartCourseItems.value = items;
    }
    const setServiceItems = (items) => {
        serviceItems.value = items;
    }
    const setLastOrderLine = (orderLine) => {
        lastOrderLine.value = orderLine;
    }
    const setDeliveryCountryCode = (deliveryCountryCode) => {
        delivery_country_code.value = deliveryCountryCode;
    }
    const setUuid = (uuid) => {
        cart_uuid.value = uuid;
    }

    return {
        events,
        cart,
        data,
        cartItems,
        cartCourseItems,
        serviceItems,
        lastOrderLine,
        cart_uuid,
        cart_currency,
        delivery_country_code,
        addToCartSelectedVariant,
        addToCartSelectedVariantModal,
        addToCartQuantity,
        addToCartQuantityModal,
        getUuid,
        getItemCount,
        getDiscounts,
        getSubtotalExcl,
        getTotal,
        getDeliveryCostExcl,
        getDiscountExcl,
        getTaxes,
        loadCart,
        updateQuantity,
        updateInstallation,
        updateCountryCode,
        addItem,
        validateDiscountCode,
        deleteDiscountCode,
        lookupAddress,
        validateVatNumber,
        deleteLastOrderLine,
        setCart,
        setData,
        setCartItems,
        setServiceItems,
        setLastOrderLine,
        setDeliveryCountryCode,
        setUuid,
        setAddToCartSelectedVariant,
        setAddToCartSelectedVariantModal,
        hasOnlyServices,
    }
});
